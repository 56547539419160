













































































import { CalendarJob } from "@/models/entities/calendar-job.interface";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CLocationInfo extends Vue {
  @Prop({ required: false, default: null, type: [Object, String] })
  private job: CalendarJob;

  private getLabel(txt: string): string {
    let label = "--";
    if (txt && txt.trim() !== "") {
      label = txt.trim();
    }
    return label;
  }
}
